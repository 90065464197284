import flatpickr from "flatpickr";
import AutoNumeric from 'autonumeric';
import { Russian } from "flatpickr/dist/l10n/ru.js"
// import { English } from "flatpickr/dist/l10n/default.js";
import { EnglishCustom } from "../custom/flatpickr_en_locale.js";
import { getMetaValue } from "helpers";
import { ApplicationController } from "../application_controller";
import IMask from 'imask';

export default class extends ApplicationController {
  static targets = ["datepicker", "datetimepicker", "timepicker",
    "daterange", "dateminmax", "daterange_period", 'calendar-alt', 'inline_timepicker']

  initialize() {
    // set locale
    const app_locale = document.querySelector('body').dataset.lang

    const locales = {
      "ru": {
        locales: Russian,
        date_format: "d-m-Y",
        datetime_format: "d-m-Y H:i",
        time_format: "H:i",
        daterange_format: "d-m-Y — d-m-Y"
      },
      "en": {
        locales: EnglishCustom,
        date_format: "Y-m-d",
        datetime_format: "Y-m-d H:i",
        time_format: "H:i",
        daterange_format: "Y-m-d to Y-m-d"
      }
    }
    // locale Options
    const locale_options = locales[app_locale];
    flatpickr.localize(locale_options["locales"]);

    // datepicker
    if (this.hasDatepickerTarget) {
      this.datepicker(this.datepickerTarget, locale_options);
      return;
    }

    if (this.hasDatetimepickerTarget) {
      this.datetimepicker(this.datetimepickerTarget, locale_options);
      return;
    }

    if (this.hasTimepickerTarget) {
      this.timepicker(this.timepickerTarget, locale_options);
      return;
    }

    if (this.hasInline_timepickerTarget) {
      this.inlineTimepicker(this.inline_timepickerTarget, locale_options);
      return;
    }

    if (this.hasDaterangeTarget) {
      this.daterange(this.daterangeTarget, locale_options);
      return;
    }

    if (this.hasDateminmaxTarget) {
      this.dateminmax(this.dateminmaxTarget, locale_options);
      return;
    }

    if (this.hasDaterange_periodTarget) {
      this.daterange_period(this.daterange_periodTarget, locale_options);
      return;
    }

  }

  //
  showCalendar(event) {
    event.preventDefault();
    let input_flatpickr = null;
    if (this.hasDaterange_periodTarget) {
      input_flatpickr = this.daterange_periodTarget.flatpickr;
    } else if (this.hasDatepickerTarget) {
      input_flatpickr = this.datepickerTarget.flatpickr;
    } else if (this.hasDatetimepickerTarget) {
      input_flatpickr = this.datetimepickerTarget.flatpickr
    } else if (this.hasTimepickerTarget) {
      input_flatpickr = this.timepickerTarget.flatpickr
    } if (this.hasDaterangeTarget) {
      input_flatpickr = this.daterangeTarget.flatpickr
    } else if (this.hasDateminmaxTarget) {
      input_flatpickr = this.dateminmaxTarget.flatpickr
    } else if (this.hasDaterange_periodTarget) {
      input_flatpickr = this.daterange_periodTarget.flatpickr
    }

    if (input_flatpickr) {
      this.show_input(input_flatpickr);
    } else {
      console.log(input_flatpickr)
    }
  }

  // private
  //show_input
  show_input(flatpickr) {
    flatpickr.open()
  }

  // datepicker
  datepicker(field, locale_options) {
    let set_year_limit = this.setYearLimit
    field.setAttribute( "autocomplete", "off" );
    field.flatpickr = flatpickr(field, {
      dateFormat: locale_options["date_format"],
      allowInput: true,
      onOpen: function (selectedDates, dateStr, instance) {
        set_year_limit(instance.currentYearElement)
      }
    });

    this.addImaskPlaceholder(field, [locale_options["date_format"]])
    this.addValidateTrigger(field, field.flatpickr)
    this.setStartDate(field, field.flatpickr)
  }

  // datetimepicker
  datetimepicker(field, locale_options) {
    let set_year_limit = this.setYearLimit
    field.setAttribute( "autocomplete", "off" );
    field.flatpickr = flatpickr(field, {
      enableTime: true,
      dateFormat: locale_options["datetime_format"],
      defaultHour: 8,
      defaultMinute: 0,
      allowInput: true,
      onOpen: function (selectedDates, dateStr, instance) {
        set_year_limit(instance.currentYearElement)
      }
    });
    this.addImaskPlaceholder(field, [locale_options["datetime_format"]])
    this.addValidateTrigger(field, field.flatpickr)
    this.setStartDate(field, field.flatpickr)
  }

  // timepicker
  timepicker(field, locale_options) {
    field.setAttribute( "autocomplete", "off" );
    field.flatpickr = flatpickr(field, {
      enableTime: true,
      noCalendar: true,
      dateFormat: locale_options["time_format"],
      time_24hr: true,
      allowInput: true,
      minuteIncrement: 1
    });
    this.addImaskPlaceholder(field, [locale_options["time_format"]])
    this.addValidateTrigger(field, field.flatpickr)
  }

  // inline-timepicker
  inlineTimepicker(field, locale_options) {
    field.setAttribute( "autocomplete", "off" );
    field.flatpickr = flatpickr(field, {
      enableTime: true,
      noCalendar: true,
      dateFormat: locale_options["time_format"],
      time_24hr: true,
      allowInput: false,
      inline: true,
      minuteIncrement: 1
    });
    this.addImaskPlaceholder(field, [locale_options["time_format"]])
    this.addValidateTrigger(field, field.flatpickr)
  }

  // dateminmax
  dateminmax(field, locale_options) {
    let min_date = null;
    let max_date = null;
    if (field.dataset.allowable_range != null) {
      [min_date, max_date] = field.dataset.allowable_range.split("|")
    }
    let set_year_limit = this.setYearLimit;
    field.setAttribute( "autocomplete", "off" );

    field.flatpickr = flatpickr(field, {
      mode: "single",
      dateFormat: locale_options["date_format"],
      minDate: min_date,
      maxDate: max_date,
      allowInput: true,
      onOpen: function (selectedDates, dateStr, instance) {
        set_year_limit(instance.currentYearElement)
      }
    });

    this.addImaskPlaceholder(field, [locale_options["date_format"]])
    this.addValidateTrigger(field, field.flatpickr)
  }


  // daterange
  daterange(field, locale_options) {
    let period = null;
    if (field.dataset.period != null) period = field.dataset.period.split("|")
    let min_date = null;
    let max_date = null;
    if (field.dataset.allowable_range != null) [min_date, max_date] = field.dataset.allowable_range.split("|")

    let set_year_limit = this.setYearLimit
    field.setAttribute( "autocomplete", "off" );

    field.flatpickr = flatpickr(field, {
      mode: "range",
      allowInput: true,
      dateFormat: locale_options["date_format"],
      defaultDate: period,
      minDate: min_date,
      maxDate: max_date,
      onClose: function (selectedDates, dateStr, instance) {
        if (dateStr.match("_")){
          instance.setDate(['',''], true)
        }
        if (selectedDates.length == 1) {
          instance.setDate([selectedDates[0], selectedDates[0]], true);
        }
      },
      onOpen: function (selectedDates, dateStr, instance) {
        set_year_limit(instance.currentYearElement)
      }
    });

    field.addEventListener('keydown', function(event){
      if((event.keyCode == 9 || event.which == 9) && this.value.match("_")){
        this.flatpickr.clear()
      }
    })

    this.addImaskPlaceholder(field, [locale_options["date_format"], locale_options["daterange_format"]])
    this.addValidateTrigger(field, field.flatpickr)
  }

  // daterange_period
  daterange_period(field, locale_options) {
    let period = null;
    if (field.dataset.period != null) period = field.dataset.period.split("|")
    let min_date = null;
    let max_date = null;
    if (field.dataset.allowable_range != null) [min_date, max_date] = field.dataset.allowable_range.split("|")
    let set_year_limit = this.setYearLimit

    // let is_set_days_count = false
    let days_count_class = field.dataset.daysCountClass;
    let days_count_closest = field.dataset.daysCountClosest;
    field.setAttribute( "autocomplete", "off" );

    field.flatpickr = flatpickr(field, {
      mode: "range",
      allowInput: true,
      dateFormat: locale_options["date_format"],
      defaultDate: period,
      minDate: min_date,
      maxDate: max_date,
      onChange: function (selectedDates, dateStr, instance) {
        const form_group = field.closest(".form-group")
        const start_at_field = form_group.querySelector("input.start_at")
        const end_at_field = form_group.querySelector("input.end_at")
        let start_at_value = ""
        let end_at_value = ""
        if (selectedDates.length == 0) {
          start_at_value = ""
          end_at_value = ""
        } else if (selectedDates.length == 1) {
          start_at_value = flatpickr.formatDate(selectedDates[0], "Y-m-d")
          end_at_value = flatpickr.formatDate(selectedDates[0], "Y-m-d")
        } else if (selectedDates.length == 2) {
          start_at_value = flatpickr.formatDate(selectedDates[0], "Y-m-d")
          end_at_value = flatpickr.formatDate(selectedDates[1], "Y-m-d")
        }
        if (start_at_field) start_at_field.value = start_at_value
        if (end_at_field) end_at_field.value = end_at_value

        if (days_count_class && days_count_closest) {
          const start_date = new Date(start_at_value).getTime();
          const end_date = new Date(end_at_value).getTime();
          // 1000 milliseconds * 60 minutes * 60 seconds * 24 hours;
          const days_count = Math.ceil((end_date - start_date) / (1000 * 3600 * 24) + 1);

          const closest_target = field.closest(days_count_closest)
          const days_count_field = closest_target.querySelector(days_count_class)
          if (days_count_field) {
            if (days_count_field.numeric) {
              days_count_field.numeric.set(days_count)
            }
            else {
              days_count_field.value = days_count
            }
            days_count_field.onkeyup();
          }

        }
      },
      onClose: function (selectedDates, dateStr, instance) {
        if (dateStr.match("_")){
          instance.setDate(['',''], true)
        }
        if (selectedDates.length == 1) {
          instance.setDate([selectedDates[0], selectedDates[0]], true);
        }
      },
      onOpen: function (selectedDates, dateStr, instance) {
        set_year_limit(instance.currentYearElement)
      }
    });

    field.addEventListener('keydown', function(event){
      if((event.keyCode == 9 || event.which == 9) && this.value.match("_")){
        this.flatpickr.clear()
      }
    })

    this.addImaskPlaceholder(field, [locale_options["date_format"], locale_options["daterange_format"]])
    this.addValidateTrigger(field, field.flatpickr)
    this.setStartDate(field, field.flatpickr)
  }


  // yearLimit
  setYearLimit(year_target) {
    if (year_target) {
      year_target.type = "text"
      year_target.pattern = "\d*"
      year_target.maxLength = 4
      year_target.addEventListener(
        "keydown",
        function (event) {
          const charCode = (event.which) ? event.which : event.keyCode
          if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            event.preventDefault();
            return false;
          }
          return true;
        }
      );
    }
  }


  go(event) {
    const url = this.data.get("url");
    const value = event.currentTarget.value;

    fetch(url + "?date_value=" + value, {
      method: "GET",
      dataType: "script",
      credentials: "include",
      headers: {
        "X-CSRF-Token": getMetaValue("csrf-token")
      },
    })
  }

  // set start month
  setStartDate(target, calendar_object) {
    let startDate = target.getAttribute("data-start-date")
    let calendarValue = target.getAttribute("value")

    if (startDate != undefined && (calendarValue == '' || calendarValue == undefined )) {
      calendar_object.jumpToDate(startDate);
    }
  }

  // Validate
  addValidateTrigger(calendar_target, calendar_object) {
    let form, controller;
    form = calendar_target.closest('form')
    if (form != undefined) controller = this.formController(form)

    if (controller != undefined && calendar_target != undefined) {
      calendar_object.config.onChange.push(function () {
        controller.checkCalendar(calendar_target);
      });

    }
  }

  // Imask
  addImaskPlaceholder(target, patterns) {
    let mask_settings = [ { mask: '' } ]

    patterns.forEach(pattern => {
      mask_settings.push({
          mask: pattern,
          lazy: false,
          overwrite: false,
          autofix: false,
          blocks: {
            d: {
              mask: IMask.MaskedRange,
              from: 1,
              to: 31,
              maxLength: 2
            },
            m: {
              mask: IMask.MaskedRange,
              from: 1,
              to: 12,
              maxLength: 2
            },
            Y: {
              mask: IMask.MaskedRange,
              from: 0,
              to: 9999,
              maxLength: 4
            },
            H: {
             mask: IMask.MaskedRange,
              from: 0,
              to: 23,
              maxLength: 2
            },
            i: {
             mask: IMask.MaskedRange,
              from: 0,
              to: 59,
              maxLength: 2
            }
          }
      })
    });

    let mask = IMask(target, { mask: mask_settings })

    target.addEventListener('change', function(){
      if(this.value.match('_')){
        mask.masked.reset()
      }
      mask.updateValue()
    })
  }

  formController(form_element) {
    return this.application.getControllerForElementAndIdentifier(form_element, "layout--form")
  }

}
